"use strict";

import React, { useEffect } from "react";

import { useGlobalContext } from "./context/global_state";
import ServiceFlowStepIndicator from "./services_flow_step_indicator";
import VideoContentSetup from "./contents/video_content_setup";
import PdfContentSetup from "./contents/pdf_content_setup";
import ExternalContentSetup from "./contents/external_content_setup";

const SolutionStep = ({next, step, step_key}) => {
  const { props, dispatch, selected_goal, selected_solution } = useGlobalContext()
  const selected_goal_option = props.service_goals.find((goal) => goal.key === selected_goal)
  const solutions = selected_goal_option.solutions

  useEffect(() => {
    if (solutions.length == 1) {
      dispatch({
        type: "SET_ATTRIBUTE",
        payload: {
          attribute: "selected_solution",
          value: solutions[0].key
        }
      })
    }
  }, [])

  if (selected_solution) {
    switch (selected_solution) {
      case "video":
        return <VideoContentSetup next={next} step={step} step_key={step_key} />
      case "pdf":
        return <PdfContentSetup next={next} step={step} step_key={step_key} />
      case "external":
        return <ExternalContentSetup next={next} step={step} step_key={step_key} />
    }
  }

  return (
    <div className="form settings-flow centerize">
      <ServiceFlowStepIndicator step={step} step_key={step_key} />
      <h3 className="header centerize">{I18n.t("user_bot.dashboards.online_service_creation.what_is_your_solution")}</h3>
      {solutions.map((solution) => {
        return (
          <button
            onClick={() => {
              if (!solution.enabled) return;

              dispatch({
                type: "SET_ATTRIBUTE",
                payload: {
                  attribute: "selected_solution",
                  value: solution.key
                }
              })
            }}
            className="btn btn-tarco btn-extend btn-flexible margin-around m10 relative"
            disabled={!solution.enabled}
            key={solution.key}>
            <h4>{solution.name}</h4>
            <p className="break-line-content text-align-left">
              {solution.description}
            </p>
            {!solution.enabled && <span className="preparing">{I18n.t('common.preparing')}</span>}
          </button>
        )
      })}
    </div>
  )

}

export default SolutionStep
