"use strict";

import React from "react";

import { useGlobalContext } from "./context/global_state";
import FlowStepIndicator from "./flow_step_indicator";

const NameStep = ({next, step}) => {
  const { dispatch, name } = useGlobalContext()

  return (
    <div className="form settings-flow centerize">
      <FlowStepIndicator step={step} />
      <h3 className="header centerize">{I18n.t("user_bot.dashboards.settings.episodes.new.what_is_episode_name")}</h3>
      <input
        type="text"
        value={name || ""}
        className="extend with-border"
        onChange={(event) =>
            dispatch({
              type: "SET_ATTRIBUTE",
              payload: {
                attribute: "name",
                value: event.target.value
              }
            })
        }
      />

      <div className="action-block">
        <button onClick={next} className="btn btn-yellow" disabled={!name}>
          {I18n.t("action.next_step")}
        </button>
      </div>
    </div>
  )

}

export default NameStep
