"use strict";

import React from "react";
import ReactPlayer from 'react-player';

import VideoContentPreview from "shared/video_content_preview";
import { useGlobalContext } from "../context/global_state";
import ServiceFlowStepIndicator from "../services_flow_step_indicator";

import { isValidHttpUrl } from "libraries/helper";

const VideoContentSetup = ({next, step, step_key}) => {
  const { dispatch, content_url } = useGlobalContext()

  return (
    <div className="form settings-flow centerize">
      <ServiceFlowStepIndicator step={step} step_key={step_key} />
      <h3 className="header centerize">{I18n.t("user_bot.dashboards.online_service_creation.what_is_this_video_provide")}</h3>
      <VideoContentPreview
        url={content_url}
        handleUrlChange={(event) =>
          dispatch({
            type: "SET_ATTRIBUTE",
            payload: {
              attribute: "content_url",
              value: event.target.value
            }
          })
        }
      />
      {content_url && ReactPlayer.canPlay(content_url) && isValidHttpUrl(content_url) && (
        <div className="action-block">
          <button onClick={next} className="btn btn-yellow" disabled={false}>
            {I18n.t("action.next_step")}
          </button>
        </div>
      )}
    </div>
  )

}

export default VideoContentSetup
